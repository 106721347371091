import { HTTP_METHODS, TAG } from '@/const';

export const UMTS = [
	{
		id: '4401',
		title: 'Money Express',
		titleCN: '国际汇款',
		endpoint: '/app/internationalRemittance',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: [
			'businessIndication',
			'custId',
			'fundSource',
			'payeeNameEnc',
			'payeePanEnc',
			// 'payerAccountEnc',
			'payerExtCardId',
			'payerIdNoEnc',
			'payerIdType',
			'payerNameEnc',
			'payerNationality',
			'txnAmt',
		],
		optionalAttributes: ['payerIdNoEnc', 'payerIdType', 'payerNationality'],
		respAttributes: ['orderNo', 'settledWithCustomerAmt'],
	},
	{
		id: '4402',
		title: 'Money Express Order Inquiry',
		titleCN: '国际汇款订单查询',
		endpoint: '/app/internationalRemittanceOrderQuery',
		method: HTTP_METHODS.POST,
		tag: TAG.UMTS,
		attributes: ['orderNo'],
		respAttributes: ['origResponseCode', 'origResponseMsg'],
	},
];
