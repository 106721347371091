export const ENUMS = {
	keyIdTypeEnum: {
		0: '卡号',
		1: '账户号',
	},
	currencyEnum: {
		// '0001': '欧元',
		// '0002': '人民币',
		'0003': '美元',
		'0004': '瑞尔',
	},
	accountStatusEnum: {
		'00': '正常',
		'02': '冻结',
		'04': '销户',
	},
	cardStatusEnum: {
		'虚拟卡 & 实体卡': {
			0: '正常',
			1: '已冻结',
			2: '未激活',
			3: '已注销',
			5: '正在销卡',
			6: '已过期',
		},
		// 匿名卡: {
		// 	0: '正常',
		// 	2: '启用',
		// 	6: '卡过期',
		// 	7: '资金过期',
		// 	9: '未激活',
		// },
	},
	keyStatusEnum: {
		Account: {
			'00': '正常',
			'02': '冻结',
			'04': '销户',
		},
		Card: {
			0: '正常',
			1: '已挂失',
			2: '未激活',
			3: '已注销',
			5: '正在销卡 [换卡专用]',
		},
	},
	allocatedStatusEnum: {
		1: '通过',
		2: '拒绝',
	},
	flagEnum: {
		Y: '已上传',
		N: '未上传',
	},
	applicationStatusEnum: {
		虚拟卡: {
			'01': '未审核',
			'06': '审核通过',
			'07': '审核拒绝',
			'08': '审核通过，激活成功',
		},
		实体卡: {
			密码卡: {
				'01': '未审核',
				'02': '初审通过',
				'03': '初审拒绝 ',
				'04': '一审通过',
				'05': '一审拒绝',
				'06': '复审通过',
				'07': '复审拒绝',
				'08': '卡/账户/客户资料建立完成',
				'09': '卡/账户/客户资料建立失败',
			},
			签名卡: {
				'01': '未审核',
				'06': '审核通过',
				'07': '审核拒绝',
				'08': '制卡中',
				'09': '制卡完成',
			},
		},
	},
	topUpTypeEnum: {
		'00': '现金',
		'01': '银行存款/转账',
		'04': 'App充值',
	},
	txnCategoryEnum: {
		1: '充值',
		2: 'POS消费',
		3: '预授权',
		4: 'ATM提现',
		5: '转账',
		6: '服务费',
		// 7: '卡激活',
		8: '单卡贷记',
		9: 'Bakong转账',
		10: '国际汇款转出',
		11: '退货',
		12: '调账',
		13: 'Bakong关联账户',
	},
	transactionStatusCodeEnum: {
		Internal: {
			2: '成功',
			3: '已冲正',
			4: '已取消',
			5: '待成功',
		},
		Bakong: {
			0: '初始化',
			1: '转账成功 [Internal]',
			2: '转账成功 [Bakong]',
			3: '转账失败 [Internal]',
			4: '转账失败 [Bakong]',
			5: '转账成功 [Both Internal & Bakong]',
			6: '退款成功',
		},
	},
	txnTypeEnum: {
		B0010: '消费',
		B0011: '单卡消费撤销',
		B0012: '单卡消费冲正',
		B0013: '单卡消费撤销冲正',
		B0020: '客户消费',
		B0030: '转账',
		B0073: '退货',
		B0074: '提现',
		B0075: 'ATM提现冲正',
		B0080: '预授权请求',
		B0081: '预授权冲正',
		B0082: '预授权撤销',
		B0083: '预授权撤销冲正',
		B0084: '预授权完成',
		B0085: '预授权完成冲正',
		B0086: '预授权完成撤销',
		B0087: '预授权完成撤销冲正',
		C0010: '单卡充值',
		C0015: '单卡贷记',
		D0010: 'ATM查询',
		ZA002: '门户充值',
		Z0030: '卡管理月费',
		Z0040: '卡管理年费',
		B1030: 'Bakong转账',
		B1031: 'Bakong转账撤销',
		B1032: 'Bakong关联账户充值',
		B2030: '国际汇款转出',
		// B0061: '调账失败',
		B0062: '调账成功',
	},
	cardTypeEnum: {
		1: '实体卡 [签名卡]',
		2: '虚拟卡 [缺省默认为实体签名卡]',
	},
	stateEnum: {
		'Internal Transfer & UMPS': {
			1: '成功',
		},
		Bakong: {
			0: '初始化',
			1: '转账成功 [Internal]',
			2: '转账成功 [Bakong]',
			3: '转账失败 [Internal)',
			4: '转账失败 [Bakong]',
			5: '转账成功 [最终]',
			6: '退款成功',
		},
		// 取消: {
		// 	0: '初始化',
		// 	1: '退款成功',
		// 	2: '退款失败',
		// },
	},
	businessIndicationEnum: {
		'06': '退税',
		'07': '中国大陆境外跨境汇款',
		'08': '中国大陆以外的国内P2P',
		A1: '中国境内汇款',
	},
	fundSourceEnum: {
		0: '现金',
		1: '借记卡',
		2: '信用卡',
		3: '账户资金',
	},
	documentTypeEnum: {
		1: '身份证',
		3: '护照',
		// 4: '营业执照',
	},
	cashflowTypeEnum: {
		1: '进钱',
		2: '出钱',
	},
	brandIdEnum: {
		'0002': 'Bongloy实体卡',
		'0003': 'Bongloy虚拟卡',
	},
	qrcodeCategoryEnum: {
		1: 'UPI境内',
		2: 'UPI境外',
	},
	qrcodeStateEnum: {
		11: '静态',
		12: '动态',
	},
	bakongTypeEnum: {
		1: 'KHQR',
		2: 'Bakong转账',
	},
	payloadFormatIndicator: {
		'01': '对于这个版本',
	},
	pointofInitiationMethodEnum: {
		11: '静态二维码',
		12: '动态二维码',
	},
	upiStateEnum: {
		0: '未开通',
		1: '已开通',
	},
	transactionStatusEnum: {
		0: '初始化',
		1: '转账成功 [Internal]',
		2: '转账成功 [Bakong]',
		3: '转账失败 [Internal)',
		4: '转账失败 [Bakong]',
		5: '转账成功 [最终]',
		6: '退款成功',
	},
	rootCauseEnum: {
		ER1001: '已达到帐户支出限额',
		ER1002: '达到账户消费上限的数量',
		ER1003: '冻结的帐户',
		ER2001: '已达到信用卡消费限额',
		ER2002: '已达信用卡消费上限',
		ER2003: '冷冻卡',
		ER2004: '卡密码错误',
	},
	physicalAppProgessEnum: {
		'01': '审查中',
		'06': '审核通过',
		'07': '审核拒绝',
		'08': '制卡中',
		'09': '制卡完成',
	},
	yesNoEnum: {
		Y: '是',
		N: '不是',
	},
	sexEnum: {
		0: '男',
		1: '女',
	},
};
